<template>
    <dashboard-layout>
        <v-container>
            <h2>Guide</h2>
            <v-container>
                <v-row justify="center">
                    <v-expansion-panels accordion>
                        <v-expansion-panel>
                            <v-expansion-panel-header><h3>Create Layout</h3></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <h4>Create, copy, delete section</h4>
                                        <video controls width="100%">
                                            <source src="/videos/help/create-section.mp4" type="video/mp4"/>
                                        </video>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <h4>Container width</h4>
                                        <p>You can set/unset container adaptive maximum-width size by using class <code>container--fluid</code>
                                        </p>
                                        <video controls width="100%">
                                            <source src="/videos/help/container-fluid.mp4" type="video/mp4"/>
                                        </video>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header><h3>Insert Text</h3></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-col cols="12" sm="6">
                                    <video controls width="100%">
                                        <source src="/videos/help/insert-text.mp4" type="video/mp4"/>
                                    </video>
                                </v-col>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header><h3>Insert Link/Button</h3></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-col cols="12" sm="6">
                                    <video controls width="100%">
                                        <source src="/videos/help/insert-link.mp4" type="video/mp4"/>
                                    </video>
                                </v-col>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header><h3>Insert Image</h3></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <h4>Insert float image</h4>
                                        <video controls width="100%">
                                            <source src="/videos/help/insert-float-image.mp4" type="video/mp4"/>
                                        </video>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <h4>Insert image as background</h4>
                                        <video controls width="100%">
                                            <source src="/videos/help/insert-bg-image.mp4" type="video/mp4"/>
                                        </video>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header><h3>Adaptivity</h3></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <video controls width="100%">
                                            <source src="/videos/help/adaptivity.mp4" type="video/mp4"/>
                                        </video>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <h4>Grid system</h4>
                                        <p>This comes with a 12 point grid system built using flexbox. It contains 4
                                            types of media breakpoints that are used for targeting specific screen sizes
                                            or orientations, <strong>sm</strong>, <strong>md</strong>,
                                            <strong>lg</strong> and <strong>xl</strong>.</p>
                                        <div class="v-data-table mb-10">
                                            <div class="v-data-table__wrapper">
                                                <table>
                                                    <thead>
                                                    <tr class="text-left">
                                                        <th>Device</th>
                                                        <th>Code</th>
                                                        <th>Type</th>
                                                        <th>Range</th>
                                                    </tr>
                                                    </thead>
                                                    <tr>
                                                        <td><span class="v-icon v-icon--left">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                                 role="img" class="v-icon__svg">
                                                                <path
                                                                    d="M19,18H5V6H19M21,4H3C1.89,4 1,4.89 1,6V18A2,2 0 0,0 3,20H21A2,2 0 0,0 23,18V6C23,4.89 22.1,4 21,4Z"></path>
                                                            </svg>
                                                        </span><span>Small</span></td>
                                                        <td><strong>sm</strong></td>
                                                        <td>Small to medium tablet</td>
                                                        <td>600px &gt; &lt; 960px</td>
                                                    </tr>
                                                    <tr>
                                                        <td><span class="v-icon v-icon--left">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                                 role="img" class="v-icon__svg">
                                                                <path
                                                                    d="M4,6H20V16H4M20,18A2,2 0 0,0 22,16V6C22,4.89 21.1,4 20,4H4C2.89,4 2,4.89 2,6V16A2,2 0 0,0 4,18H0V20H24V18H20Z"></path>
                                                            </svg>
                                                        </span><span>Medium</span></td>
                                                        <td><strong>md</strong></td>
                                                        <td>Large tablet to laptop</td>
                                                        <td>960px &gt; &lt; 1264px*</td>
                                                    </tr>
                                                    <tr>
                                                        <td><span class="v-icon v-icon--left">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                                 role="img" class="v-icon__svg">
                                                                <path
                                                                    d="M21,16H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10V20H8V22H16V20H14V18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z"></path>
                                                            </svg>
                                                        </span><span>Large</span></td>
                                                        <td><strong>lg</strong></td>
                                                        <td>Desktop</td>
                                                        <td>1264px &gt; &lt; 1904px*</td>
                                                    </tr>
                                                    <tr>
                                                        <td><span class="v-icon v-icon--left">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                                 role="img" class="v-icon__svg">
                                                                <path
                                                                    d="M21,17H3V5H21M21,3H3A2,2 0 0,0 1,5V17A2,2 0 0,0 3,19H8V21H16V19H21A2,2 0 0,0 23,17V5A2,2 0 0,0 21,3Z"></path>
                                                            </svg>
                                                        </span><span>Extra large</span></td>
                                                        <td><strong>xl</strong></td>
                                                        <td>4k and ultra-wide</td>
                                                        <td>&gt; 1904px*</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <h4>Breakpoint sizing</h4>
                                        <p>
                                            Columns will automatically take up an equal amount of space within their
                                            parent container. This can be modified using the class "col-*". You can also
                                            utilize the sm, md, lg, and xl props to further define how the column will
                                            be sized in different viewport sizes. For example:
                                        </p>
                                        <v-container class="grey lighten-5">
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="6" md="8">
                                                    <v-card class="pa-2" outlined tile>
                                                        .col-12 .col-sm-6 .col-md-8
                                                    </v-card>
                                                </v-col>
                                                <v-col cols="6" md="4">
                                                    <v-card class="pa-2" outlined tile>
                                                        .col-6 .col-md-4
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>
            </v-container>
        </v-container>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from "@/layouts/DashboardLayout";
// import Page from "@/models/Page";

export default {
    name: "help-index",
    components: {DashboardLayout},
    data: function () {
        return {
            loading: false,
            pages: [],
        }
    },
    methods: {},
    async mounted() {
        this.loading = true
        // this.pages = await Page.get()
        this.loading = false
    },
}
</script>

<style scoped>

</style>
